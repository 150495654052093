import { useMemo, useState } from "react";
import {
  DeleteBtnContainer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  InfoContainer,
  InputNumberContainer,
  ProductDetails,
  StyledDrawer,
  TotalPrice,
} from "./style";
import { useCollection, useCollectionActions } from "../../context/collections";
import { Col, Image, notification, Tooltip } from "antd";
import { ProductType } from "../../types/type";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import RequestModal from "../Request/RequestModal";
import emailjs from "emailjs-com";
import { DeleteFilled, InfoCircleOutlined } from "@ant-design/icons";
import SecondaryButton from "../Buttons/SecondaryButton";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { transformToHyphenated } from '../../helpers';
import CustomInputNumber from '../Input/CustomInputNumber';

const CustomDrawer = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { state } = useCollection();
  const { t } = useTranslation();
  const { toggleDrawer, removeCollection, resetCollection, updateQuantity } =
    useCollectionActions();
  const [modalVisible, setModalVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const totalPrice = useMemo(() => {
    // Calculate the base total price from the items and quantities
    const baseTotalPrice = state.items.reduce((acc: number, value: any) => {
      const price = value?.price?.replace('.', '')?.replace(',', '.');
      const quantity = value?.quantity || 1; // Default to 1 if quantity is undefined
      return acc + (parseFloat(price) || 0) * quantity;
    }, 0);

    // Calculate VAT (21% of the base price)
    const vatAmount = (baseTotalPrice / 121) * 21;
    const totalWithVAT = baseTotalPrice + vatAmount;

    return { baseTotalPrice, vatAmount, totalWithVAT };
  }, [state.items]);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (values: any) => {
    setLoading(true);
    const collectionImages = state.items.reduce((acc: any, value: any, index: number) => {
      acc[`collection_${index + 1}`] = value.medium;
      return acc;
    }, {});

    const emailData: any = {
      fullName: values?.fullName,
      phoneNumber: values?.phoneNumber,
      email: values?.email,
      links: values?.links,
      totalPrice: values?.totalPrice,
      dateTime: values?.dateTime ? moment(values.dateTime).isValid() ? moment(values.dateTime).format('DD-MM-yyyy') : '' : '',
      description: values?.description,
      ...(values?.link_1 && { link_1: values?.link_1 }),
      ...(values?.link_2 && { link_2: values?.link_2 }),
      ...(values?.link_3 && { link_3: values?.link_3 }),
      ...(values?.link_4 && { link_4: values?.link_4 }),
      ...(values?.link_5 && { link_5: values?.link_5 }),
      ...(values?.link_6 && { link_6: values?.link_6 }),
      ...collectionImages,
    };

    emailjs.send('service_4qnk66m', 'template_u4ota17', emailData, 'YvW3RqHC-ysKQb-aq')
      .then(() => {
        api.success({
          message: `Call request sent successfully`,
          placement: "top",
        });
        setEmailSent(true);
      }, (error) => {
        api.error({
          message: `Email send error: ${error.text}`,
          placement: 'top',
        });
      })
      .finally(() => {
        setLoading(false)
      });
    setModalVisible(false);
  };

  const onProducts = () => {
    toggleDrawer();
    navigate("/shop");
  };

  const onVisitProduct = (technicalDescription: string) => {
    toggleDrawer();
    const transformedTechnicalDescription = transformToHyphenated(technicalDescription);
    navigate(`/products/${transformedTechnicalDescription}`);
  };

  const onCollections = () => {
    toggleDrawer();
    navigate("/collections");
  };

  const handleCreateNewCollection = () => {
    resetCollection();
    setEmailSent(false);
    toggleDrawer();
  };

  const onRemoveCollection = (e: any, id: string) => {
    e.stopPropagation();
    removeCollection(id);
  };

  return (
    <StyledDrawer
      title={
        <DrawerHeader onClick={onCollections}>
          <p>{t('collection.title')}</p>
        </DrawerHeader>
      }
      width={442}
      placement="right"
      closable={false}
      onClose={toggleDrawer}
      open={state.showDrawer}
      key="right"
      footer={
        <DrawerFooter>
          <TotalPrice>
            <div>
              <InfoContainer>
                <p>
                  <b>{t('collection.total_price')}{' '}</b>
                  € {totalPrice.baseTotalPrice.toFixed(2)}
                </p>
                <Tooltip placement="topRight" title={
                  <>
                    <span>{t('collection.info_transport')}</span>
                    {/* <p>{t('collection.discount_info')}</p> */}
                  </>
                }>
                  <PrimaryButton type="text" icon={<InfoCircleOutlined />} />
                </Tooltip>
              </InfoContainer>
              <p className="vat">
                <b>
                  {`(Incl. ${t('collection.VAT')}: € ${totalPrice.vatAmount.toFixed(2)})`}
                </b>
              </p>
            </div>
          </TotalPrice>
          <PrimaryButton
            onClick={handleModalOpen}
            type="primary"
            disabled={emailSent}
          >
            {emailSent ? t('button.request_sent') : t('button.request_call')}
          </PrimaryButton>
          {emailSent && (
            <PrimaryButton onClick={handleCreateNewCollection} type="primary">
              {t('button.create_new_collection')}
            </PrimaryButton>
          )}
          <p onClick={onProducts}>{t('collection.continue_adding_collection')}</p>
        </DrawerFooter>
      }
    >
      {contextHolder}
      <div>
        {state.items.map((pr: ProductType & { quantity?: number }) => (
          <DrawerBody onClick={() => onVisitProduct(pr.technicalDescription as string)}>
            <Col span={6}>
              <Image
                // width={68}
                height={71}
                src={pr.url || pr.medium || "https://placehold.co/  "}
                fallback="https://placehold.co/600"
              />
            </Col>
            <Col span={16}>
              <ProductDetails>
                <p>{pr.name || pr.technicalDescription}</p>
                {/* <p>€ {pr.price}</p> */}
                {/* <p>{pr.subtitle || pr.bANQADescriptionENG}</p> */}
              </ProductDetails>
            </Col>
            <Col span={2}>
              <DeleteBtnContainer>
                <SecondaryButton onClick={(e: any) => onRemoveCollection(e, pr.id as string)} shape='circle' icon={<DeleteFilled />} />
              </DeleteBtnContainer>
            </Col>
            <InputNumberContainer>
              <CustomInputNumber
                size="small"
                value={pr.quantity as number}
                defaultValue={pr.quantity || 1}
                onIncrement={() => updateQuantity(pr.id, 1, null)}
                onDecrement={() => updateQuantity(pr.id, -1, null)}
                onChange={(value: number | null) => {
                  if (value !== null) {
                    updateQuantity(pr.id, null, value);
                  }
                }}
              />
            </InputNumberContainer>
          </DrawerBody>
        ))}
        <RequestModal
          visible={modalVisible}
          onCancel={handleModalCancel}
          onFinish={handleFormSubmit}
          loading={loading}
        />
      </div>
    </StyledDrawer>
  );
};

export default CustomDrawer;
