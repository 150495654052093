import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
  ReactNode,
  useEffect
} from 'react';
import { ProductType } from '../types/type';

const initialState: CollectionState = {
  items: [],
  showDrawer: false,
  collectionScreenshot: ''
};

interface CollectionState {
  items: (ProductType & { quantity?: number })[];
  showDrawer: boolean,
  collectionScreenshot: string
}

type CollectionAction =
  | { type: 'INITIALIZE'; payload: ProductType[] }
  | { type: 'ADD_PRODUCT'; payload: ProductType }
  | { type: 'REMOVE_PRODUCT'; payload: string }
  | { type: 'UPDATE_QUANTITY'; payload: { id: string | number; change: number | null, value: number | null } }
  | { type: 'TOGGLE_DRAWER' }
  | { type: 'RESET_COLLECTION' }
  | { type: 'SET_COLLECTION_SCREENSHOT'; payload: string };

interface CollectionProviderProps {
  children: ReactNode
}

const CollectionContext = createContext<{
  state: CollectionState;
  dispatch: React.Dispatch<CollectionAction>;
}>({
  state: { ...initialState },
  dispatch: () => null,
});

const collectionReducer = (state: CollectionState, action: CollectionAction): CollectionState => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        items: action.payload,
      };
    case 'ADD_PRODUCT':
      localStorage.setItem('collections', JSON.stringify([...state.items, action.payload]));
      return {
        ...state,
        items: [...state.items, { ...action.payload, quantity: 1 }],
      };
    case 'REMOVE_PRODUCT':
      localStorage.setItem('collections', JSON.stringify(state.items.filter(item => item.id !== action.payload)));
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    case 'UPDATE_QUANTITY': {
      const updatedItems = state.items.map(item => {
        if (item.id === action.payload.id) {
          let updatedQuantity = item.quantity || 1;

          // If `change` is provided, adjust the quantity accordingly
          if (action.payload.change !== null) {
            updatedQuantity = Math.max(updatedQuantity + action.payload.change, 1); // ensure quantity doesn't go below 1
          }

          // If `value` is provided, set quantity to that value directly
          if (action.payload.value !== null) {
            updatedQuantity = action.payload.value;
          }

          return { ...item, quantity: updatedQuantity };
        }
        return item;
      });

      localStorage.setItem('collections', JSON.stringify(updatedItems));
      return { ...state, items: updatedItems };
    }
    case 'TOGGLE_DRAWER':
      return {
        ...state,
        showDrawer: !state.showDrawer
      }
    case 'RESET_COLLECTION':
      localStorage.removeItem('collections');
      return {
        ...state,
        items: initialState.items
      }
    case 'SET_COLLECTION_SCREENSHOT':
      return {
        ...state,
        collectionScreenshot: action.payload
      }
    default:
      return state;
  }
}

export const useCollection = () => {
  return useContext(CollectionContext);
}

export const CollectionProvider: React.FC<CollectionProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(collectionReducer, { ...initialState });

  useEffect(() => {
    const storedItems = localStorage.getItem('collections');
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      dispatch({ type: 'INITIALIZE', payload: parsedItems });
    }
  }, []);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CollectionContext.Provider value={contextValue}>
      {children}
    </CollectionContext.Provider>
  );
};

export const useCollectionActions = () => {
  const { dispatch } = useContext(CollectionContext);

  const addCollection = useCallback((item: ProductType) => {
    dispatch({ type: 'ADD_PRODUCT', payload: item });
  }, [dispatch]);

  const removeCollection = useCallback((id: string) => {
    dispatch({ type: 'REMOVE_PRODUCT', payload: id });
  }, [dispatch]);

  const toggleDrawer = useCallback(() => {
    dispatch({ type: 'TOGGLE_DRAWER' });
  }, [dispatch]);

  const resetCollection = useCallback(() => {
    dispatch({ type: 'RESET_COLLECTION' });
  }, [dispatch]);

  const setCollectionScreenshot = useCallback((screenshot: string) => {
    dispatch({ type: 'SET_COLLECTION_SCREENSHOT', payload: screenshot })
  }, [dispatch]);

  const updateQuantity = useCallback((id: string | number, change: number | null, value: number | null) => {
    dispatch({
      type: 'UPDATE_QUANTITY',
      payload: { id, change, value }
    });
  }, [dispatch]);

  return {
    addCollection,
    removeCollection,
    toggleDrawer,
    resetCollection,
    setCollectionScreenshot,
    updateQuantity
  };
}
