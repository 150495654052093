import React, { useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { ProductType } from '../../types/type';
import Icon from '../../components/Icon/Icon';
import { CategoryText, Container, LinkContainer, TranslationWrapper } from './style';
import { MenuProps } from 'antd';
import useGetAllCategories, { useGetSubCategory } from '../../hooks/categories/useGetAllCategories';
import Spinner from '../../components/common/Spinner';
import { useCategoryFilter } from '../../store/useCategoryFilter';
import { useDrawer } from '../../store/useDrawer';
import { useProducts } from '../../store/useProducts';
import useGetAllProducts from '../../hooks/products/useGetAllProducts';
import { usePagination } from '../../store/usePagination';
import { useNavigate } from 'react-router-dom';
interface CollectionState {
  items: ProductType[];
  showDrawer: boolean;
  collectionScreenshot: string;
}

type SidebarProps = {
  i18n: { language: string };
  items: MenuProps['items'];
  onChangeLanguage: (key: string) => void;
  selectedLanguage: string;
  t: (key: string) => string;
  state: CollectionState;
  pathname: string;
  toggleDrawer: () => void;
  toggleMenu: () => void;
  banqaLogo: string;
};

const Sidebar: React.FC<SidebarProps> = ({
  i18n,
  onChangeLanguage,
  selectedLanguage,
  t,
  state,
  pathname,
  // toggleDrawer,
}) => {
  const navigate = useNavigate();
  const {
    data: categories,
    fetchData: fetchAllCategories,
    loading: isFetchingAllCategories,
  } = useGetAllCategories();

  const {
    fetchData: fetchSubCategories,
    loading: isFetchingSubCategories,
  } = useGetSubCategory();

  const {
    selectedCategory,
    setSelectedCategory,
    setSelectedSubcategories,
    setSelectedType,
    clearCategory
  } = useCategoryFilter();
  const { open, setOpen } = useDrawer();
  const { products, setFilteredProducts } = useProducts();
  const { setCurrentPage, setItemsPerPage } = usePagination();
  const {
    // fetchData: fetchAllProducts,
    loading: isFetchingAllProducts,
  } = useGetAllProducts();

  const onSelectCategory = (code: string, type: string, docId?: string) => {
    // fetchAllProducts();
    setSelectedSubcategories([]);
    setSelectedType(type);
    setCurrentPage(1);
    if (docId) {
      fetchSubCategories(docId);
    }

    if (code === selectedCategory) {
      clearCategory();
      setSelectedType('shop');
      setOpen(!open);
      return;
    }

    if (type === 'shop') {
      setItemsPerPage(3);
    } else {
      setItemsPerPage(30);
    }

    setSelectedCategory(code);
    const filteredAllProducts = products.filter((product: any) => {
      if (typeof product.itemCategory === 'object') {
        return product.itemCategory.includes(code);
      } else {
        return product.itemCategory === code;
      }
    });
    setFilteredProducts(filteredAllProducts);
    setOpen(!open);
    navigate('/shop');
  };

  const onNavigateToShop = () => {
    setSelectedSubcategories([]);
    setCurrentPage(1);
    setItemsPerPage(3);
    setSelectedType('shop');
    clearCategory();
    setOpen(false);
    navigate('/shop');
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  if (isFetchingAllCategories || isFetchingAllProducts || isFetchingSubCategories) {
    return <Spinner size="large" />
  }
  return (
    <Container>
      <LinkContainer>
        <a href="https://www.banqa.es">{t('navbar.home')}</a>
        <CategoryText selected={false} onClick={() => onNavigateToShop()}>{t('navbar.shop')}</CategoryText>
        {(categories ?? []).map(
          (category: {
            id: string;
            url: string;
            code: string;
            name: string;
            nameEs: string;
            type: string;
          }) => (
            <CategoryText
              key={category.id}
              selected={selectedCategory === category.code}
              onClick={() => onSelectCategory(category.code, 'product', category.id)}
            >
              {category.name}
            </CategoryText>
          ))}
        <CategoryText
          selected={selectedCategory === 'project'}
          onClick={() => onSelectCategory('project', 'project')}
        >
          Projects
        </CategoryText>
        {/* <a href="https://www.banqa.es/portfolio">{t('navbar.portfolio')}</a>
        <a href="https://www.banqa.es/brands">{t('navbar.brands')}</a>
        <a href="https://www.banqa.es/homestaging/">{t('navbar.homestaging')}</a>
        <a href="https://www.banqa.es/interior-designer/">{t('navbar.interiorDesigner')}</a>
        <a href="https://www.banqa.es/about/">{t('navbar.about')}</a>
        <a href="https://www.banqa.es/art/">{t('navbar.art')}</a> */}
        <a href="https://www.banqa.es/contact-us/">{t('navbar.contact')}</a>
        {pathname !== '/collections' && (
          <div style={{ display: 'flex', alignItems: 'center', padding: '.5rem' }}>
            <span>{state.items.length}</span>
            <button
              onClick={() => {
                setOpen(false);
                navigate('/collections');
              }}
              style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
            >
              <Icon name="collections" size={20} />
            </button>
          </div>
        )}
        <TranslationWrapper onClick={() => onChangeLanguage(i18n.language === 'gb' ? 'es' : 'gb')}>
          <ReactCountryFlag
            countryCode={selectedLanguage}
            svg
            style={{
              width: '3em',
              height: '3em',
              marginRight: '0.5em',
            }}
          />
          <span className="language-text">
            {i18n.language === 'gb' ? 'EN' : 'ES'}
          </span>
        </TranslationWrapper>
      </LinkContainer>
    </Container>
  );
};

export default Sidebar;
