import React from "react";
import { Button, InputNumber, InputNumberProps } from "antd";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface CustomInputNumberProps extends Omit<InputNumberProps, 'onChange'> {
  defaultValue?: number;
  value: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onIncrement?: () => void;
  onDecrement?: () => void;
  onChange: (value: number | null) => void;
}

const StyledInputNumber = styled(InputNumber)`
  width: 50%;
  flex: none !important;

  > .ant-input-number-wrapper .ant-input-number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
   >.ant-input-number-input-wrap input {
      text-align: center;
      height: 100%;
    }
  }
`;

const IconButton = styled(Button)`
  padding: 0 !important;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease; /* Adding a smooth transition effect for hover */

  &:hover {
    color: ${theme.color.primary} !important;
    cursor: pointer;
  }
`;

const StopPropagationWrapper = styled.span`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const CustomInputNumber: React.FC<CustomInputNumberProps> = ({
  defaultValue = 0,
  value = 0,
  min = 0,
  max,
  disabled,
  onIncrement,
  onDecrement,
  onChange,
  ...props
}) => {
  const handleIncrement = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onIncrement) onIncrement();
  };

  const handleDecrement = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDecrement) onDecrement();
  };

  const handleChange = (newValue: number | string | null, e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e) e.stopPropagation();
    if (typeof newValue === 'number' && newValue > 0) {
      onChange(newValue);
    }
  };

  const isDecrementDisabled = disabled || (min !== undefined && value <= min);
  const isIncrementDisabled = disabled || (max !== undefined && value >= max);

  return (
    <StopPropagationWrapper onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
      <StyledInputNumber
        style={{ flex: 1 }}
        addonBefore={
          <IconButton
            type="text"
            disabled={isDecrementDisabled}
            onClick={handleDecrement}
            icon={<MinusOutlined />}
          />
        }
        addonAfter={
          <IconButton
            type="text"
            disabled={isIncrementDisabled}
            onClick={handleIncrement}
            icon={<PlusOutlined />}
          />
        }
        min={min}
        max={max}
        value={value}
        defaultValue={defaultValue}
        onChange={(value) => handleChange(value)}
        disabled={disabled}
        controls={false}
        {...props}
      />
    </StopPropagationWrapper>
  );
};

export default CustomInputNumber;
