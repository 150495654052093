import { Col, DatePicker, Form, Input, Row } from "antd";
import PrimaryButton from "../Buttons/PrimaryButton";
import TextArea from "antd/es/input/TextArea";
import { v4 as uuidv4 } from "uuid";
import { TAIL_FORM_ITEM_LAYOUT } from "../../constants";
import { useCollection } from "../../context/collections";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { transformToHyphenated } from '../../helpers';
import { useMemo } from 'react';

const RequestForm = ({ onFinish, loading }: any) => {
  const { state: collections } = useCollection();
  const { t } = useTranslation();
  const domain = window.location.origin;
  const productLinks = collections.items.map(
    (item) => `${domain}/products/${transformToHyphenated(item.technicalDescription || item.technicalDescriptionEs || '')}`
  );

  const [form] = Form.useForm();
  const fullName = Form.useWatch("fullName", form);
  const email = Form.useWatch("email", form);
  const phoneNumber = Form.useWatch("phoneNumber", form);
  const dateTime = Form.useWatch("dateTime", form);
  const description = Form.useWatch("description", form);

  const totalPrice = useMemo(() => {
    // Calculate the base total price from the items and quantities
    const baseTotalPrice = collections.items.reduce((acc: number, value: any) => {
      const price = value?.price?.replace('.', '')?.replace(',', '.');
      const quantity = value?.quantity || 1; // Default to 1 if quantity is undefined
      return acc + (parseFloat(price) || 0) * quantity;
    }, 0);

    // Calculate VAT (21% of the base price)
    const vatAmount = baseTotalPrice * 0.21;
    const totalWithVAT = baseTotalPrice + vatAmount;

    return { baseTotalPrice, vatAmount, totalWithVAT };
  }, [collections.items]);

  const handleSubmit = async (values: any) => {
    const id = uuidv4();
    const { fullName, email, phoneNumber, description } = values;

    await setDoc(doc(db, "requests", id), {
      id,
      fullName,
      email,
      phoneNumber,
      description,
      status: 'pending',
      dateTime: values?.dateTime ? new Date(values?.dateTime) : "",
      collection: collections.items.map((collection) => ({
        id: collection.id,
        thumb: collection.thumb,
        medium: collection.medium,
        itemNum: collection.itemNum,
        itemNo: collection.itemNo ?? '',
        images: collection.images,
        bANQADescription: collection.bANQADescriptionENG,
        bANQADescriptionES: collection.bANQADescriptionES,
        technicalDescription: collection.technicalDescription,
        technicalDescriptionES: collection.technicalDescriptionEs,
        price: collection.price
      })),
      totalPrice: totalPrice,
      createdAt: serverTimestamp()
    });
    onFinish(values, form); // Pass form values to parent component
    return values;
    // form.resetFields(); // Reset form fields after submission
  };

  return (
    <Form
      form={form}
      id="request-form"
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        links: productLinks.join("\n"),
        link_1: productLinks[0] || '',
        link_2: productLinks[1] || '',
        link_3: productLinks[2] || '',
        link_4: productLinks[3] || '',
        link_5: productLinks[4] || '',
        link_6: productLinks[5] || '',
        collection: collections.collectionScreenshot,
        description: '',
        dateTime: '',
        totalPrice: totalPrice.totalWithVAT.toFixed(2)
      }}
    >
      <Form.Item
        name="fullName"
        label={fullName ? t('form.label.full_name') : ""}
        rules={[{ required: true, message: t('form.error.full_name') }]}
      >
        <Input placeholder={t('form.placeholder.full_name')} name="fullName" />
      </Form.Item>
      <Form.Item
        name="email"
        label={email ? t('form.label.email') : ""}
        rules={[
          { required: true, message: t('form.error.email') },
          { type: "email", message: t('form.error.valid_email') },
        ]}
      >
        <Input placeholder={t('form.placeholder.email')} name="email" />
      </Form.Item>
      <Row gutter={[10, 10]} style={{ alignItems: 'flex-end' }}>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={phoneNumber ? t('form.label.phone_number') : ""}
            rules={[
              { required: true, message: t('form.error.phone_number') },
              {
                pattern: /^[0-9]+$/,
                message: t('form.error.valid_phone_number'),
              },
            ]}
          >
            <Input name="phoneNumber" placeholder={t('form.placeholder.phone_number')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dateTime"
            label={dateTime ? t('form.label.preferred_date_of_call') : ""}
          >
            <DatePicker
              disabledDate={d => !d || d.isBefore(moment().format('YYYY-MM-DD'))}
              name="dateTime"
              showTime
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              placeholder={t('form.placeholder.preferred_date_of_call')}

            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="description"
        label={description ? t('form.label.brief_description') : ""}
      >
        <TextArea
          name="description"
          rows={4}
          placeholder={t('form.placeholder.brief_description')}
        />
      </Form.Item>
      <Form.Item name="link_1" hidden>
        <TextArea name="link_1" rows={1} />
      </Form.Item>

      <Form.Item name="link_2" hidden>
        <TextArea name="link_2" rows={1} />
      </Form.Item>

      <Form.Item name="link_3" hidden>
        <TextArea name="link_3" rows={1} />
      </Form.Item>

      <Form.Item name="link_4" hidden>
        <TextArea name="link_4" rows={1} />
      </Form.Item>

      <Form.Item name="link_5" hidden>
        <TextArea name="link_5" rows={1} />
      </Form.Item>

      <Form.Item name="link_6" hidden>
        <TextArea name="link_6" rows={1} />
      </Form.Item>

      <Form.Item name="links" hidden>
        <TextArea name="links" rows={4} />
      </Form.Item>
      <Form.Item name="collection" hidden>
        <Input name="collection" />
      </Form.Item>
      <Form.Item name="total_price" hidden>
        <Input name="total_price" />
      </Form.Item>

      {/* <Form.Item
        name="projectDetails"
        label="Collection/Project name and other details"
        // initialValue="Project XYZ" // Example of readonly field value
      >
        <Input readOnly />
      </Form.Item> */}
      <Form.Item {...TAIL_FORM_ITEM_LAYOUT}>
        <PrimaryButton
          type="primary"
          htmlType="submit"
          style={{ marginTop: 20 }}
          disabled={loading}
          loading={loading}
        >
          {t('button.submit')}
        </PrimaryButton>
      </Form.Item>
    </Form>
  );
};

export default RequestForm;
